import axios from 'axios';
import { authHeader } from './auth-header';

export const userService = {
    login,
    logout,
    register,
    getAll,
};

function login(email, password) {
    const axiosServices = axios.create({
        baseURL: process.env.VUE_APP_BASE_URL,
    });
    return axiosServices.post(`anunciantes/login`, { email, password })
        .then(response => {
            const data = response.data;

            const user = data?.me

            if (data.code && data.token) {
                user.token = data.token
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        })
        .catch(handleError);
}

function logout() {
    localStorage.removeItem('user');
}

function register(user) {
    const axiosServices = axios.create({
        baseURL: process.env.VUE_APP_BASE_URL,
    });
    return axiosServices.post(`anunciantes`, user)
        .then(response => {
            const data = response.data;

            const user = data?.me
            if (data.code && data.token) {
                user.token = data.token
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        })
        .catch(handleError);
}

function getAll() {
    return axios.get(`/users`, { headers: authHeader() })
        .then(response => response.data)
        .catch(handleError);
}

function handleError(error) {
    const { response } = error;
    if (response && response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
    }
    const errorMessage = (response && response.data && response.data.message) || error.message;

    return Promise.reject(errorMessage);
}