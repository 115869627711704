import store from '@/state/store';

export default [
  {
    path: '/',
    redirect: '/influencers'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/pages/account/login'),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters['authApi/loggedIn']) {
          next({
            name: 'home'
          });
        } else {
          next();
        }
      },
    },
  },
  {
    path: '/cadastro',
    name: 'cadastro',
    component: () => import('../views/pages/account/register'),
    meta: {
      title: "Cadastro",
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters['authApi/loggedIn']) {
          next({
            name: 'home'
          });
        } else {
          next();
        }
      },
    },
  },
  {
    path: '/recuperar-senha',
    name: 'recuperar-senha',
    component: () => import('../views/pages/account/forgot-password'),
    meta: {
      title: "Recuperar Senha",
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters['authApi/loggedIn']) {
          next({
            name: 'home'
          });
        } else {
          next();
        }
      },
    },
  },
  {
    path: '/alterar-senha',
    name: 'alterar-senha',
    component: () => import('../views/pages/account/change-password'),
    meta: {
      title: "Alterar Senha",
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters['authApi/loggedIn']) {
          next({
            name: 'home'
          });
        } else {
          next();
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      title: "Logout",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch('auth/logOut');
        } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
          store.dispatch('authfack/logout');
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.push('/login')
        );

        next(authRequiredOnPreviousRoute ? {
          name: 'home'
        } : {
          ...routeFrom
        });
      },
    },
  },
  {
    path: '/',
    name: 'dashboard',
    meta: { title: "Dashboard", authRequired: true },
    component: () => import('../views/pages/explorer/influencers')
  },
  {
    path: '/influencers',
    name: 'influencers',
    meta: { title: "Influencers", authRequired: true },
    component: () => import('../views/pages/explorer/influencers')
  },
  {
    path: '/busca-avancada',
    name: 'busca',
    meta: { title: "Busca Avançada", authRequired: true },
    component: () => import('../views/pages/explorer/search')
  },
  {
    path: '/relatorio',
    name: 'relatorio',
    meta: { title: "Relatórios", authRequired: true },
    component: () => import('../views/pages/influencer/reports')
  },
  {
    path: '/propostas',
    name: 'proposals',
    meta: { title: "Propostas", authRequired: true },
    component: () => import('../views/pages/proposals/index')
  },
  {
    path: '/propostas/:id',
    name: 'proposals detail',
    meta: { title: "Detalhes da Propostas", authRequired: true },
    component: () => import('../views/pages/proposals/detail')
  },
  {
    path: '/propostas/:id/upload',
    name: 'proposal upload',
    meta: { title: "Upload na Proposta", authRequired: true },
    component: () => import('../views/pages/proposals/upload')
  },
  {
    path: '/propostas/:id/chat',
    name: 'proposals chat',
    meta: { title: "Chat da Proposta", authRequired: true },
    component: () => import('../views/pages/proposals/chat')
  },
  {
    path: '/influencer',
    name: 'influencer',
    meta: { title: "Relatórios", authRequired: true },
    component: () => import('../views/pages/influencer/profile')
  },
  {
    path: '/financeiro/faturas',
    name: 'Faturas',
    meta: { title: "Faturas", authRequired: true },
    component: () => import('../views/pages/finances/invoices/index')
  },
  {
    path: '/financeiro/depositar',
    name: 'Depositar',
    meta: { title: "Depositar", authRequired: true },
    component: () => import('../views/pages/finances/deposit/index')
  },
  {
    path: '/500',
    name: '500',
    meta: { title: "500", authRequired: true },
    component: () => import('../views/pages/utility/500')
  },
  {
    path: '/404',
    name: '404',
    meta: { title: "404", authRequired: true },
    component: () => import('../views/pages/utility/404')
  },
  {
    path: '/integracoes/nuvemshop',
    name: 'nuvemshop',
    meta: { title: "NuvemShop", authRequired: false },
    component: () => import('../views/pages/integrations/nuvemshop')
  },
  {
    path: '/auth/recoverpwd',
    name: 'recoverpwd',
    meta: { title: "Recover Password", authRequired: true },
    component: () => import('../views/pages/auth/recoverpwd')
  },
];